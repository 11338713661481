<template>
  <nav
    role="presentation"
    aria-label="Main Navigation"
    class="main-nav"
    v-if="items.length"
  >
    <button
      id="open-menu"
      class="main-nav__reveal"
      aria-owns="mobile-menu"
      aria-haspopup="true"
      @click="toggleMenu()"
      @keyup.enter="toggleMenu()"
    >
      <span class="sr-only">Toggle the mobile menu</span>
      <icon :name="menuTriggerIcon" class="main-nav__reveal-icon"></icon>
      <span class="main-nav__reveal-text" aria-hidden="true">{{
        menuTriggerText
      }}</span>
    </button>

    <div
      :class="{ 'main-nav__list-wrap': true, open: mobileMenuOpen }"
      id="mobile-menu"
      data-hidden="true"
    >
      <ul class="main-nav__list">
        <li
          v-if="!disableSearch"
          tabindex="-1"
          :class="{
            'main-nav__item': true,
            'main-nav__item--search': true,
            'main-nav__item--mobile': true,
          }"
        >
          <SimpleSearch class="main-nav__search main-nav__search--mobile" />
        </li>

        <li
          v-for="item in items"
          :key="item.ID"
          tabindex="-1"
          :class="{
            'main-nav__item': true,
            'main-nav__item--has-submenu': item.Children.length,
            'main-nav__item--no-submenu': !item.Children.length,
            'open current':
              itemOpen === item.ID || $route.path.startsWith(item.Url),
            hidden: hideMenuItems,
          }"
          @mouseover="openMenu(item)"
          @mouseleave="closeMenu(item)"
        >
          <div class="main-nav__link-wrap">
            <a
              :href="item.Url"
              @click.prevent="clicked(item)"
              :class="{
                'main-nav__link': true,
                current: $route.path.startsWith(item.Url),
                open: itemOpen === item.ID,
              }"
              :aria-expanded="itemOpen === item.ID ? 'true' : 'false'"
              :aria-haspopup="item.Children.length ? 'true' : 'false'"
              >{{ item.Title }}</a
            >
            <button
              v-if="item.Children.length"
              :aria-label="'Open ' + item.Title + ' sub menu'"
              :aria-expanded="itemOpen === item.ID ? 'true' : 'false'"
              :class="{ 'main-nav__submenu-trigger': true }"
              :aria-controls="'nav-children-' + item.ID"
              @click.prevent="toggleMobileSubMenu(item)"
            >
              <icon
                :name="menuItemIcon(item)"
                :class="{ 'main-nav__icon': true, open: itemOpen === item.ID }"
              ></icon>
            </button>
          </div>
          <hr
            :class="{ 'main-nav__item-sep': true, open: itemOpen === item.ID }"
          />
          <ul
            :aria-labelledby="'nav-item-btn-' + item.ID"
            :aria-hidden="itemOpen === item.ID ? 'true' : 'false'"
            :id="'nav-children-' + item.ID"
            :class="{
              'main-nav__submenu': true,
              open: itemOpen === item.ID,
            }"
            v-if="item.Children.length"
          >
            <li
              class="main-nav__item main-nav__item--submenu"
              v-for="child in item.Children"
              :key="'child' + child.ID"
              @click="clicked(child)"
            >
              <a
                :href="child.Url"
                :class="{
                  'main-nav__link': true,
                  'main-nav__link--submenu': true,
                }"
                data-nav-item="nav-children-{$ID}"
                >{{ child.Title }}</a
              >
            </li>
          </ul>
        </li>

        <li
          v-if="!disableSearch"
          tabindex="-1"
          :class="{
            'main-nav__item': true,
            'main-nav__item--search': true,
            'main-nav__item--desktop': true,
          }"
        >
          <SimpleSearch
            class="main-nav__search main-nav__search--desktop"
            :is-collapsible="true"
            @form:open="hideMenuItems = true"
            @form:close="hideMenuItems = false"
          />
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import SimpleSearch from "@/components/SimpleSearch";

export default {
  components: {
    SimpleSearch,
  },

  props: {
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    disableSearch: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },

  data() {
    return {
      itemOpen: null,
      mobileMenuOpen: false,
      closeMenuTimeout: null,
      hideMenuItems: false,
    };
  },

  computed: {
    menuTriggerIcon() {
      return this.mobileMenuOpen ? "close" : "menu";
    },

    menuTriggerText() {
      return this.mobileMenuOpen ? "Close" : "Menu";
    },
  },

  watch: {
    $route() {
      // close mobile menu when route changes
      this.mobileMenuOpen = false;
    },
  },

  methods: {
    menuItemIcon(item) {
      if (this.isMobile()) {
        return this.itemOpen === item.ID ? "caret-down" : "caret-right";
      }
      return this.itemOpen === item.ID ? "caret-up" : "caret-down";
    },

    toggleMenu() {
      this.mobileMenuOpen = !this.mobileMenuOpen;
    },

    toggleMobileSubMenu(item) {
      if (!item.Children.length) {
        this.itemOpen = null;
        this.clicked(item);
      } else {
        this.itemOpen = this.itemOpen === item.ID ? null : item.ID;
      }
    },

    clicked(item) {
      window.location = item.Url;
    },

    isMobile() {
      return window.matchMedia("(max-width: 991px)").matches;
    },

    closeMenu() {
      if (this.isMobile()) {
        return;
      }

      this.closeMenuTimeout = setTimeout(() => (this.itemOpen = null), 800);
    },

    openMenu(item) {
      if (this.isMobile()) {
        return;
      }

      clearTimeout(this.closeMenuTimeout);
      this.itemOpen = item.ID;
    },
  },
};
</script>

<style lang="scss">
.main-nav {
  background-color: var(--color-bg-header);

  @include media-breakpoint-up(lg) {
    width: 100%;
    height: 100%;
    margin-left: rem(54px);
    display: flex;
  }
}

.main-nav__reveal {
  border: 2px solid
    var(--color-br-header-mobile-menu, var(--color-br-header-btn));
  background-color: var(--color-bg-header-btn);
  color: var(--color-fg-header-btn);
  border-radius: var(
    --radius-br-header-mobile-menu,
    var(--radius-br-header-btn)
  );
  padding: var(--padding-header-mobile-menu);
  display: flex;
  align-items: center;

  .fa-icon {
    vertical-align: middle;
  }

  @include media-breakpoint-up(sm) {
    border-color: var(--color-br-header-btn);
    border-radius: var(
      --radius-br-tablet-header-mobile-menu,
      var(--radius-br-header-btn)
    );
    padding: rem(7px) rem(16px);
  }

  @include media-breakpoint-up(lg) {
    display: none;
  }
}

.main-nav__reveal-text {
  display: none;
  font-size: rem(20px);
  font-weight: 500;
  letter-spacing: 0;
  line-height: rem(28px);

  @include media-breakpoint-up(sm) {
    display: inline-block;
  }
}

.main-nav__reveal-icon {
  width: 1.5em;
  height: 1.5em;
  vertical-align: -0.225em;

  @include media-breakpoint-up(sm) {
    margin-right: rem(10px);
  }
}

.main-nav__list-wrap {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  display: none;
  width: 100%;

  &.open {
    display: block;
  }

  @include media-breakpoint-up(lg) {
    position: static;
    display: block;
  }
}

.main-nav__list {
  @include list-reset();
  position: absolute;
  top: $header-mobile-height;
  left: 0;
  z-index: 1;
  width: 100%;
  overflow: auto;
  background-color: var(--color-bg-mobile-header, var(--color-bg-header));
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);

  @include media-breakpoint-up(sm) {
    max-width: rem(348px);
    right: rem($gutter);
    left: auto;
  }
  @include media-breakpoint-up(md) {
    top: $header-desktop-height;
  }

  @include media-breakpoint-up(lg) {
    position: relative;
    top: 0;
    right: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    overflow: visible;
    border: 0;
    height: 100%;
    min-width: rem(610px);
    box-shadow: none;
    max-width: none;
    background-color: var(--color-bg-header);
  }
}

.main-nav__item {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  color: var(--color-fg-mobile-header-link, var(--color-fg-header-link));
  position: relative;
  z-index: 2;

  &:active,
  &:hover {
    background-color: var(--color-bg-active-header-link);
    color: var(--color-fg-active-mobile-header-link);

    @include media-breakpoint-up(lg) {
      color: var(--color-fg-hover-desktop-header-link);
      background-color: transparent;
    }
  }

  @include media-breakpoint-up(lg) {
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--color-fg-header-link);

    &:not(.main-nav__item--submenu),
    &:not(.main-nav__item--has-submenu) {
      padding: 0;
    }

    &:not(:last-child):not(.main-nav__item--submenu) {
      margin-right: rem(21px);
    }
  }

  &.current {
    background-color: var(--color-bg-active-header-link);

    @include media-breakpoint-up(lg) {
      background-color: transparent;
    }
  }

  &.hidden {
    opacity: 0;
  }

  &--desktop {
    display: none;

    @include media-breakpoint-up(lg) {
      display: flex;
    }
  }

  &--mobile {
    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  &--no-submenu {
    @include media-breakpoint-up(lg) {
      .main-nav__submenu-trigger {
        display: none;
      }

      .main-nav__link {
        padding-right: 0;
      }
    }
  }
}

.main-nav__item-sep {
  height: 1px;
  background-color: var(--color-separator-mobile-header);
  border: none;
  width: calc(100% - #{rem(24px)});
  margin: auto;

  @include media-breakpoint-up(lg) {
    opacity: 0;
    transition: opacity linear $anim-hover;
    height: 4px;
    border-radius: 7px;
    border: none;
    margin: 0;
    width: 100%;
    background-color: var(--color-separator-desktop-header);

    .main-nav__item.open &,
    .main-nav__item:hover & {
      opacity: 1;
    }
  }

  &.open,
  .main-nav__item:nth-last-child(2) &,
  .main-nav__item--current & {
    display: none;

    @include media-breakpoint-up(lg) {
      display: block;
    }
  }
}

.main-nav__submenu-trigger,
.main-nav__link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: rem(9px) rem(10px);
  transition: background-color linear $anim-hover, color linear $anim-hover;
  color: inherit;
  font-family: var(--font-family);
  font-size: var(--font-size-header-link);
  font-weight: var(--font-weight-header-link, 500);
  line-height: rem(28px);
  text-decoration: none;
  word-break: break-word;
}

.main-nav__item--submenu,
.main-nav__link--submenu {
  color: var(--color-fg-submenu-header-link);
}

.main-nav__submenu-trigger {
  @include btn-reset();
  height: rem(45px);
  width: rem(50px);
  justify-content: center;
  cursor: pointer;
  margin-left: rem(12px);

  @include media-breakpoint-up(lg) {
    width: auto;
    height: auto;
  }
}

.main-nav__icon {
  color: currentColor;
  transition: transform $anim-transform;
  pointer-events: none;

  &.open {
    @include media-breakpoint-up(lg) {
      color: var(--color-fg-hover-header-link);
    }
  }
}

.main-nav__link {
  width: calc(100% - #{rem(65px)});

  @include media-breakpoint-up(lg) {
    width: 100%;
    padding-left: 0;
    position: relative;
    z-index: 1;
  }
}

.main-nav__link-wrap {
  width: 100%;
  display: flex;

  > .main-nav__link {
    @include media-breakpoint-up(xl) {
      max-width: rem(200px);
    }
  }
}

.main-nav__submenu {
  @include list-reset();
  display: none;
  background-color: var(
    --color-bg-mobile-submenu-header,
    var(--color-bg-submenu-header)
  );
  color: var(--color-fg-submenu-header-link);
  width: 100%;

  @include media-breakpoint-up(lg) {
    width: auto;
    background-color: var(--color-bg-submenu-header);

    &:before {
      content: "";
      display: block;
      background: inherit;
      height: rem(2px);
      width: 100%;
      position: absolute;
      top: rem(-2px);
    }
  }

  &:active,
  &:hover {
    color: var(
      --color-fg-active-mobile-header-link,
      var(--color-fg-hover-header-link)
    );
  }

  &.open {
    display: block;

    @include media-breakpoint-up(lg) {
      &,
      .main-nav__item:hover & {
        position: absolute;
        top: rem($header-desktop-height + 2);
        transform: translate(#{rem(-24px)}, 0);
        min-width: rem(610px);
        left: 0;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        padding: rem(12px) 0;
      }
    }
  }
}

.main-nav__link--submenu {
  width: 100%;
  display: block;
  border-bottom: 1px solid #adadad;
  padding: 0 0 rem(12px) rem(12px);
  margin: rem(10px) rem(10px) 0 rem(10px);

  &:last-child {
    @include media-breakpoint-up(lg) {
      border-bottom: none;
    }
  }

  &:active,
  &:hover {
    text-decoration: none;
    color: var(--color-fg-active-mobile-header-link);

    @include media-breakpoint-up(lg) {
      text-decoration: underline;
      color: var(--color-fg-hover-submenu-header-link);
    }
  }

  @include media-breakpoint-up(sm) {
    padding: 0 0 rem(12px) rem(12px);
    margin: rem(10px) 0 0 0;
    width: calc(100% - #{rem(24px)});
  }

  @include media-breakpoint-up(lg) {
    height: auto;
    padding: rem(12px) rem(12px);
    margin: 0;
  }
}

.main-nav__search {
  &--mobile {
    padding: rem(12px);
  }
}

.main-nav__item--search {
  background-color: var(--color-bg-search-header);
}

.main-nav__item--desktop {
  &,
  &:hover {
    background-color: var(--color-bg-header);
  }
}

@media print {
  .main-nav {
    display: none;
  }
}
</style>
