<template>
  <header id="site-header" class="site-header">
    <div class="header-bar">
      <div class="container header-bar__inner">
        <router-link :to="{ name: 'home' }" class="header-bar__logo">
          <img
            class="header-bar__logo-img"
            :src="logo"
            v-if="logo"
            :alt="$appSettings.SiteTitle + ' logo'"
          />
        </router-link>
        <MainMenu :items="menuItems" :disable-search="disableSearch" class="header-bar__menu" />
      </div>
    </div>
  </header>
</template>

<script>
import MainMenu from "@/components/MainMenu.vue";
import get from "lodash/get";

export default {
  components: {
    MainMenu,
  },

  data() {
    return {
      menuItems: [],
      disableSearch: false,
    };
  },

  created() {
    this.menuItems = get(this.$appSettings, "Header.Navigation", []);
    this.disableSearch = get(this.$appSettings, "Header.DisableSearch", false);
  },

  computed: {
    logo() {
      const headerLogos = this.$appSettings.Header.Logos;
      if (this.$route.name === "home") {
        return headerLogos.Home || headerLogos.Logo;
      }

      return headerLogos.Logo || null;
    },
  },
};
</script>

<style lang="scss">
.header-bar {
  height: $header-mobile-height;
  background-color: var(--color-bg-header);

  @include media-breakpoint-up(md) {
    height: rem($header-desktop-height);
  }
}

.header-bar__inner {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  height: 100%;

  @include media-breakpoint-between(lg, xl) {
    padding-right: rem(12px);
    padding-left: rem(12px);
  }
}

.header-bar__logo {
  height: rem(78px);
  width: rem(250px);

  @include media-breakpoint-up(md) {
    width: rem(320px);
    height: rem(80px);
  }
}

.header-bar__logo-img {
  object-fit: contain;
  object-position: center;
  display: block;
  height: 100%;
}

@media print {
  .header-bar {
    height: rem($header-mobile-height);
  }

  .header-bar__inner {
    display: block;
  }

  .header-bar__logo {
    height: rem(78px);
    width: rem(250px);
  }
}
</style>
